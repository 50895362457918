@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,700&subset=cyrillic&display=swap');

body {
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: 'IBM Plex Sans', sans-serif
}

.ReactModal__Body--open,
.ReactModal__Html--open {
    overflow: hidden;
}
