.middle
    left: 50%
    position: absolute
    text-align: center
    top: 50%
    transform: translateX(-50%) translateY(-50%)

.pointerLoader
  display: block
  width: 52px
  height: 52px
  overflow: hidden
  transform: scale(0.5)

.pointerLoader1
  display: block
  width: 24px
  height: 24px
  float: left
  margin: 0 4px 4px 0
  border-top-left-radius: 50%
  border-top-right-radius: 50%
  border-bottom-left-radius: 50%
  border-bottom-right-radius: 0
  background-color: #C9D3CE
  animation-name: leaf1
  animation-duration: 1s
  animation-delay: 0s
  animation-iteration-count: infinite
  animation-timing-function: linear
  animation-direction: normal

.pointerLoader2
  display: block
  width: 24px
  height: 24px
  float: right
  margin: 0 0 4px
  border-top-left-radius: 50%
  border-top-right-radius: 50%
  border-bottom-left-radius: 0
  border-bottom-right-radius: 50%
  background-color: #C9D3CE
  animation-name: leaf2
  animation-duration: 1s
  animation-delay: .25s
  animation-iteration-count: infinite
  animation-timing-function: linear
  animation-direction: normal

.pointerLoader3
  display: block
  width: 24px
  height: 24px
  float: right
  margin: 0 0 0 4px
  border-top-left-radius: 0
  border-top-right-radius: 50%
  border-bottom-left-radius: 50%
  border-bottom-right-radius: 50%
  background-color: #C9D3CE
  animation-name: leaf3
  animation-duration: 1s
  animation-delay: .5s
  animation-iteration-count: infinite
  animation-timing-function: linear
  animation-direction: normal

.pointerLoader4
  display: block
  width: 24px
  height: 24px
  float: left
  margin: 0
  border-top-left-radius: 50%
  border-top-right-radius: 0
  border-bottom-left-radius: 50%
  border-bottom-right-radius: 50%
  background-color: #C9D3CE
  animation-name: leaf4
  animation-duration: 1s
  animation-delay: .75s
  animation-iteration-count: infinite
  animation-timing-function: linear
  animation-direction: normal

@keyframes leaf1
  0%
    opacity: 0.25
  100%
    opacity: 1

@keyframes leaf2
  0%
    opacity: 0.25
  100%
    opacity: 1

@keyframes leaf3
  0%
    opacity: 0.25
  100%
    opacity: 1

@keyframes leaf4
  0%
    opacity: 0.25
  100%
    opacity: 1

.loaderMiddle,
.suspenseLoader
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.titleMiddle
  text-align: center
  margin-top: 1em
  color: rgba(0,0,0,.85)
  font-weight: 600
  font-size: 25px
  line-height: 1.23