@import 'Styles/utils.sass'

.errorPageMesssage404
  display: block
  width: auto
  min-width: 361px
  max-width: 456px
  height: auto
  margin: 0
  padding: 226px 0 40px
  background-color: transparent
  background-image: url('~Images/404.svg')
  background-repeat: no-repeat
  background-position: 50% 40px
  background-size: auto 168px
  font-size: 20px
  line-height: 28px
  text-align: center
  color: #87938f
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  @media (max-height: 500px)
    position: static
    margin: 0 auto
    transform: none
  @media (max-width: 479px)
    width: 100%
    min-width: auto
    background-size: calc(100% - 24px) auto

.asInnerBlock
  position: relative
  top: auto
  left: auto
  transform: none
  -webkit-transform: none
  margin: 0 auto

.errorPageMesssage500
  display: block
  width: auto
  min-width: 358px
  max-width: 456px
  height: auto
  margin: 0
  padding: 226px 0 40px
  background-color: transparent
  background-image: url('~Images/500.svg')
  background-repeat: no-repeat
  background-position: 50% 40px
  background-size: auto 168px
  font-size: 20px
  line-height: 28px
  text-align: center
  color: #87938f
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  @media (max-height: 500px)
    position: static
    margin: 0 auto
    transform: none
  @media (max-width: 479px)
    width: 100%
    min-width: auto
    background-size: calc(100% - 24px) auto
